import { env } from "./fe-ui/utils/env";

export enum AppEnv {
  Mock = "mock",
  Test = "test",
  Production = "production",
}

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
};

export const NAV = {
  W_BASE: 260,
  W_MAIN_PAGE_MOBILE: "100%",
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};

export const HOTEL_SINGLE_PAGE = {
  OFFERS_AND_UNITS_PER_PAGE: 12,
} as { OFFERS_AND_UNITS_PER_PAGE: 4 | 12 };

export const MIN_ADULTS_COUNT = 1;
export const MAX_ADULTS_COUNT = 10;
export const MIN_CHILDREN_COUNT = 0;
export const MAX_CHILDREN_COUNT = 10;
export const MIN_PRICE = 0;
export const MAX_PRICE = 5000;

export const NEW_ARRIVAL_OFFER_ATTRIBUTE_IDS = env("NEXT_PUBLIC_APP_ENV") === AppEnv.Production ? [952] : [30, 31, 34];
export const SPECIAL_OFFER_ATTRIBUTE_IDS = env("NEXT_PUBLIC_APP_ENV") === AppEnv.Production ? [918] : [30, 31, 34];
export const ATB_HOTEL_ATTRIBUTE_ID = env("NEXT_PUBLIC_APP_ENV") === AppEnv.Production ? 692 : 155;

export const MAPBOX_API_KEY = env("MAPBOX_API_KEY");
export const MAPBOX_SEARCH_MAP_THEME = "streets-v12";
export const MAPBOX_SINGLE_UNIT_LOC_DYNAMIC_MAP_THEME = "light-v11";
export const MAPBOX_SINGLE_UNIT_LOC_STATIC_MAP_THEME = "light-v11";
